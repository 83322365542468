import React from "react";
import Html from "../assets/html.png";
import Css from "../assets/css.png";
import Javascript from "../assets/javascript.png";
import Git from "../assets/git.png";
import Github from "../assets/github.png";
import ReactImg from "../assets/react.png";
import Typescript from "../assets/typescript.png";
import Emotion from "../assets/emotion.png";
import Tailwind from "../assets/tailwind.png";
import Figma from "../assets/figma.png";
import Storybook from "../assets/storybook.png";
import Jquery from "../assets/jquery.png";
import FramerMotion from "../assets/FramerMotion.png";
import Firebase from "../assets/firebase.png";

const Skills = () => {
  return (
    <div name="skills" className="w-screen h-auto pt-[120px] bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[975px] mx-auto px-10 flex flex-col justify-center w-full h-full">
        {/* Skills Title + Description */}
        <div>
          <p className="text-2xl md:text-4xl font-bold text-[#ccd6f6] inline border-b-4 border-pink-700">
            Skills
          </p>
          <p className="lg:text-xl pt-3 md:pt-5 text-[#8892b0] text-center">
            Web development
            <span className="text-pink-700"> technologies</span>,
            <span className="text-pink-700"> frameworks</span>,
            <span className="text-pink-700"> libraries</span> and
            <span className="text-pink-700"> tools</span> I have
            <span className="text-pink-700"> hands-on experience</span> with and
            use to plan, design, build and implement my projects.
          </p>
        </div>
        {/* Skills Icons Container */}
        <div className="w-full flex flex-wrap justify-center gap-5 text-center py-2 md:py-10 text-gray-400 font-medium text-lg text-transform: uppercase">
          {/* Git Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#f3501d] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Git} alt="Git Icon" />
            <p className="mt-3">Git</p>
          </div>
          {/* Github Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#eeeeee] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Github} alt="Github Icon" />
            <p className="mt-3">Github</p>
          </div>
          {/* Figma Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#00cf84] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Figma} alt="Figma Icon" />
            <p className="mt-3">Figma</p>
          </div>
          {/* HTMl5 Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#f16529] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Html} alt="HTML5 Icon" />
            <p className="mt-3">HTML5</p>
          </div>
          {/* CSS3 Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#039be5] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Css} alt="CSS3 Icon" />
            <p className="mt-3">CSS3</p>
          </div>
          {/* jQuery Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#0868ab] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Jquery} alt="jQuery Icon" />
            <p className="mt-3">jQuery</p>
          </div>
          {/* Javascript Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#ffd600] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img
              className="w-28 mx-auto"
              src={Javascript}
              alt="Javascript Icon"
            />
            <p className="mt-3">Javascript</p>
          </div>
          {/* Typescript Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#007acc] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img
              className="w-28 mx-auto"
              src={Typescript}
              alt="Typescript Icon"
            />
            <p className="mt-3">Typescript</p>
          </div>
          {/* Storybook Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#ff4785] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img
              className="w-28 mx-auto"
              src={Storybook}
              alt="Storybook Icon"
            />
            <p className="mt-3">Storybook</p>
          </div>
          {/* React Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#00d8ff] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img
              className="w-28 mx-auto animate-spin"
              src={ReactImg}
              alt="React Icon"
            />
            <p className="mt-3">React js</p>
          </div>
          {/* Emotion Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#ba1d92] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Emotion} alt="Emotion Icon" />
            <p className="mt-3">Emotion CSS</p>
          </div>
          {/* Tailwind Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#44a8b3] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Tailwind} alt="Tailwind Icon" />
            <p className="mt-3">Tailwind CSS</p>
          </div>
          {/* Framer Motion Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#006eff] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img
              className="w-28 mx-auto"
              src={FramerMotion}
              alt="FramerMotion Icon"
            />
            <p className="mt-3">Framer Motion</p>
          </div>
          {/* Firebase Icon */}
          <div className="bg-[#142339] shadow-lg shadow-[black] hover:shadow-xl hover:shadow-[#fcca3f] hover:scale-110 duration-500 pt-6 pb-3 rounded-2xl basis-52">
            <img className="w-28 mx-auto" src={Firebase} alt="Firebase Icon" />
            <p className="mt-3">Firebase</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
