import React from "react";
import andrearizzo from "../assets/andrearizzo.mt.jpg";
import theweatherapp from "../assets/the-weather-app.com.jpg";
import andrearizzophoto from "../assets/andrearizzophoto.com.png";

const Projects = () => {
  return (
    <div
      name="projects"
      className="w-screen h-auto lg:h-screen pt-[120px] bg-[#0a192f]"
    >
      {/* Container */}
      <div className="max-w-[975px] mx-auto px-10 flex flex-col justify-center w-full h-full">
        {/* Projects Title + Description */}
        <div>
          <p className="text-2xl md:text-4xl font-bold text-[#ccd6f6] inline border-b-4 border-pink-700">
            Projects
          </p>
          <p className="lg:text-xl pt-3 md:pt-5 text-[#8892b0] text-center">
            Check out some projects which I have{" "}
            <span className="text-pink-700">designed</span> and{" "}
            <span className="text-pink-700">created</span>.
          </p>
        </div>
        {/* Project Icons Container */}
        <div className="w-auto flex flex-wrap justify-center gap-5 text-center py-2 md:py-10 text-gray-400 font-medium text-lg ">
          {/* Project - www.andrearizzo.mt */}
          <div className="relative max-w-[275px] flex-col items-center justify-center bg-[#142339] px-5 pb-5 pt-3 shadow-lg shadow-[black] rounded-2xl group hover:bg-gradient-to-br from-[#142339] via-[#9e096f] to-[#ff96d5]">
            {/* Project Title */}
            <p className="text-xs sm:text-base lg:text-lg group-hover:opacity-20 duration-500">
              www.andrearizzo.mt
            </p>
            {/* Project Icon */}
            <img
              className="mt-3 project-icon-div rounded-xl group-hover:opacity-10 duration-500"
              src={andrearizzo}
              alt="andrea rizzo website"
            />
            {/* BG Overlay + Demo and Code Buttons to show up on Hover */}
            <div className="hidden group-hover:block w-full px-3 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] duration-500">
              <div className="text-center">
                {/* Demo Button */}
                <a
                  href="https://www.andrearizzo.mt/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-auto hover:bg-pink-700 hover:border-pink-700 border-white text-center duration-500">
                    Live Demo
                  </button>
                </a>
                {/* Code Button - disabled for now eventually to connect to Github repo */}
                {/* <a href=" ">
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-[75px] hover:bg-pink-700 hover:border-pink-700 border-white ml-5 duration-500">
                    Code
                  </button>
                </a> */}
              </div>
            </div>
          </div>

          {/* ------------- */}

          {/* Project - www.the-weather-app.com */}
          <div className="relative max-w-[275px] flex-col items-center justify-center bg-[#142339] px-5 pb-5 pt-3 shadow-lg shadow-[black] rounded-2xl group hover:bg-gradient-to-br from-[#142339] via-[#9e096f] to-[#ff96d5]">
            {/* Project Title */}
            <p className="text-xs sm:text-base lg:text-lg group-hover:opacity-20 duration-500">
              www.the-weather-app.com
            </p>
            {/* Project Icon */}
            <img
              className="mt-3 project-icon-div rounded-xl group-hover:opacity-10 duration-500"
              src={theweatherapp}
              alt="the weather app website"
            />
            {/* BG Overlay + Demo and Code Buttons to show up on Hover */}
            <div className="hidden group-hover:block w-full px-3 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] duration-500">
              <div className="text-center">
                {/* Demo Button */}
                <a
                  href="https://www.the-weather-app.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-auto hover:bg-pink-700 hover:border-pink-700 border-white text-center duration-500">
                    Live Demo
                  </button>
                </a>
                {/* Code Button - disabled for now eventually to connect to Github repo */}
                {/* <a href=" ">
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-[75px] hover:bg-pink-700 hover:border-pink-700 border-white ml-5 duration-500">
                    Code
                  </button>
                </a>*/}
              </div>
            </div>
          </div>

          {/* ------------- */}

          {/* Project - www.andrearizzophoto.com */}
          <div className="relative max-w-[275px] flex-col items-center justify-center bg-[#142339] px-5 pb-5 pt-3 shadow-lg shadow-[black] rounded-2xl group hover:bg-gradient-to-br from-[#142339] via-[#9e096f] to-[#ff96d5]">
            {/* Project Title */}
            <p className="text-xs sm:text-base lg:text-lg group-hover:opacity-20 duration-500">
              www.andrearizzophoto.com
            </p>
            {/* Project Icon */}
            <img
              className="mt-3 project-icon-div rounded-xl group-hover:opacity-10 duration-500"
              src={andrearizzophoto}
              alt="andrearizzophoto website"
            />
            {/* BG Overlay + Demo and Code Buttons to show up on Hover */}
            <div className="hidden group-hover:block w-full px-3 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] duration-500">
              <div className="text-center">
                {/* Demo Button */}
                <a
                  href="https://www.andrearizzophoto.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-auto hover:bg-pink-700 hover:border-pink-700 border-white text-center duration-500">
                    Live Demo
                  </button>
                </a>
                {/* Code Button - disabled for now eventually to connect to Github repo */}
                {/* <a href=" ">
                  <button className=" bg-white font-medium text-transform: uppercase border-2 px-2 py-2 w-[75px] hover:bg-pink-700 hover:border-pink-700 border-white ml-5 duration-500">
                    Code
                  </button>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
