import React from "react";

const About = () => {
  return (
    <div name="about" className="w-screen h-screen pt-[120px] bg-[#0a192f]">
      <div className="flex flex-col justify-center items-center w-full h-full">
        {/* Container */}
        <div className="max-w-[975px] px-10 ">
          {/* About Title */}
          <div className="w-full grid grid-cols-2 gap-8">
            <div className="lg:text-right">
              <p className="text-2xl md:text-4xl font-bold text-[#ccd6f6] inline border-b-4 border-pink-700">
                About
              </p>
            </div>
          </div>
          {/* About Description */}
          <div className="w-full grid lg:grid-cols-2 gap-3 md:gap-8 pt-3 md:pt-5">
            <div className="lg:text-right text-xl md:text-2xl font-bold text-[#8892b0]">
              <p>
                I'm Andrea, nice to meet you. <br></br> Please take a look
                around.
              </p>
            </div>
            <div className="lg:text-xl text-justify text-[#8892b0] ">
              I'm passionate about front-end web development and building
              <span className="text-pink-700">
                {" "}
                exceptional web applications
              </span>{" "}
              which drastically improve the overall{" "}
              <span className="text-pink-700"> user experience</span>, beginning
              with the visual aspect and working through the intuitiveness and
              ease of use of such applications.
              <p className="mt-3 text-pink-700 ">
                <em>
                  What would you do if you had a web developer available at your
                  fingertips ?
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
