import React from "react";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="w-screen h-screen pt-[120px] bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[975px] mx-auto px-10 flex flex-col justify-center h-full">
        <p className="text-lg lg:text-2xl text-pink-700">Hi, my name is</p>
        <h1 className="text-3xl md:text-7xl font-bold text-[#ccd6f6]">
          Andrea Rizzo
        </h1>
        <h2 className="text-3xl lg:text-6xl font-bold text-[#8892b0]">
          I'm a Web Developer.
        </h2>
        <p className="py-2 lg:py-7 text-2xl md:text-3xl  text-[#8892b0]">
          Welcome to my digital
          <span className="text-pink-700 font-bold"> Playground</span>
        </p>
        <div className=" w-max ">
          <Link to="projects" smooth={true} delay={500} duration={800}>
            <button className=" text-gray-400 font-medium text-transform: uppercase group border-2 text-sm px-3 py-3 my-3 sm:text-base sm:px-4 sm:py-4 flex items-center hover:bg-pink-700 hover:border-pink-700 border-gray-400 duration-500">
              <span className="group-hover:rotate-[-90deg] duration-300">
                <HiArrowNarrowLeft className="mr-3" size={25} />
              </span>
              View Projects
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3" size={25} />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
