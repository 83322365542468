import React, { useState, useEffect } from "react";

const Contact = () => {
  const initialValues = { name: "", email: "", phone: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    // console.log(formErrors);

    // if no errors present and isSubmit is true
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(formValues);

      // make fetch request to send Contact Form values to my email
      fetch("https://formsubmit.co/ajax/8a2a234867f248ddc845c33228c44e0d ", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Name: formValues.name,
          Email: formValues.email,
          Phone: formValues.phone,
          Message: formValues.message,
        }),
      })
        .then((response) => response.json())
        // if success show success Notification and clear input fields
        .then(
          (data) => setSuccessNotification(true),
          setFormValues({
            ...formValues,
            name: "",
            email: "",
            phone: "",
            message: "",
          })
        )
        // if error show error Notification and leave input fields filled
        .catch((error) => setErrorNotification(true));

      // finally set states to false again after 5 seconds so any notifications disappear
      setTimeout(function () {
        setIsSubmit(false);
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  //input fields validations
  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z ]*$/;
    const phoneRegex = /^[0-9]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // Name
    if (!values.name) {
      errors.name = "Name required!";
    } else if (!nameRegex.test(values.name)) {
      errors.name = "Name letters only!";
    }
    // Email
    if (!values.email) {
      errors.email = "Email required!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email format invalid!";
    }
    // Phone
    if (!values.phone) {
      errors.phone = "Phone required!";
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = "Phone numbers only!";
    }
    // Message
    if (!values.message) {
      errors.message = "Message required!";
    }
    return errors;
  };

  return (
    <div
      name="contact"
      className="w-screen h-auto lg:h-screen pt-[120px] bg-[#0a192f] flex justify-center items-center"
    >
      {/* Container */}
      <form
        onSubmit={handleSubmit}
        className="max-w-[600px] mx-auto px-10 w-full flex flex-col"
      >
        {/* Contact Title + Description */}
        <div>
          <p className="text-2xl md:text-4xl  font-bold text-[#ccd6f6] inline border-b-4 border-pink-700">
            Contact
          </p>
          <p className="lg:text-xl pt-3 md:pt-5 text-[#8892b0] text-center">
            Let's talk about{" "}
            <span className="text-pink-700 font-bold">You</span> and discuss
            your <span className="text-pink-700"> ideas</span> together.
          </p>
          <p className="lg:text-xl pt-0 md:pt-3 text-[#8892b0] text-center">
            Get in
            <span className="text-pink-700"> touch</span> and drop me a{" "}
            <span className="text-pink-700">message</span>!
          </p>
        </div>

        {/* Name Input Field */}
        <input
          className="bg-[#ccd6f6] p-1 md:p-2 mt-3 md:mt-5 placeholder-[#8892b0] text-[#8892b0] !outline-none"
          type="text"
          placeholder="Name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
        {/* Email Input Field  */}
        <input
          className="bg-[#ccd6f6] p-1 md:p-2 my-3 md:my-5 placeholder-[#8892b0] text-[#8892b0] !outline-none"
          type="text"
          placeholder="Email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
        />
        {/* Phone Input Field */}
        <input
          className="bg-[#ccd6f6] p-1 md:p-2 mb-3 md:mb-5 placeholder-[#8892b0] text-[#8892b0] !outline-none"
          type="tel"
          placeholder="Phone"
          name="phone"
          value={formValues.phone}
          onChange={handleChange}
        />
        {/* Message Textarea Field  */}
        <textarea
          className="bg-[#ccd6f6] p-1 md:p-2 mb-3 md:mb-5 placeholder-[#8892b0] text-[#8892b0] !outline-none"
          rows="8"
          placeholder="Message"
          name="message"
          value={formValues.message}
          onChange={handleChange}
        />

        {/* Let's Collaborate Buttons */}
        <div>
          {formValues.name !== "" &&
          formValues.email !== "" &&
          formValues.phone !== "" &&
          formValues.message !== "" ? (
            <>
              {/* Enabled Button only when all Input fields are filled in */}
              <button
                className="relative group text-gray-400  font-medium
                text-transform: uppercase group border-2 text-sm px-3 py-1 my-3 sm:text-base sm:px-6 sm:py-3 sm:my-5 flex
                items-center mx-auto bg-pink-700 border-pink-700
                duration-500"
              >
                Let's Collaborate
                <span className="absolute top-0 right-0 translate-x-[50%] translate-y-[-50%] flex justify-center items-center">
                  {/* Pulsing LED effect = active */}
                  <span className="absolute animate-ping h-7 w-7 rounded-full bg-[#ccd6f6] opacity-100 "></span>
                  <span className="absolute rounded-full h-3 w-3 bg-[#ccd6f6] "></span>
                </span>
              </button>
            </>
          ) : (
            <>
              {/* Disabled Button when not all Input fields are filled in */}
              <button
                disabled={true}
                className="relative group text-gray-700 font-medium text-transform: uppercase group border-2 text-sm px-3 py-1 my-3 sm:text-base sm:px-6 sm:py-3 sm:my-5 flex items-center mx-auto  border-gray-700 cursor-not-allowed duration-500"
              >
                Let's Collaborate
                <span className="absolute top-0 right-0 translate-x-[50%] translate-y-[-50%] flex justify-center items-center">
                  {/* Note Pulsing LED effect = not active */}
                  <span className=""></span>
                  <span className="absolute rounded-full h-3 w-3 bg-gray-700 duration-500 "></span>
                </span>
              </button>
            </>
          )}
        </div>
      </form>

      {/* Validation Errors / Success Notifications */}
      <div>
        {/* Name Error Notification */}
        <div
          className={
            formErrors.name
              ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[0px] text-transform: uppercase font-medium bg-red-700 duration-500"
              : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[-250px] text-transform: uppercase font-medium bg-red-700 duration-200"
          }
        >
          <p>{formErrors.name}</p>
        </div>
        {/* Email Error Notification */}
        <div
          className={
            formErrors.email
              ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[179px] right-[0px] text-transform: uppercase font-medium bg-red-700 duration-500"
              : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[179px] right-[-250px] text-transform: uppercase font-medium bg-red-700 duration-200"
          }
        >
          <p>{formErrors.email}</p>
        </div>
        {/* Phone Error Notification */}
        <div
          className={
            formErrors.phone
              ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[233px] right-[0px] text-transform: uppercase font-medium bg-red-700 duration-500"
              : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[233px] right-[-250px] text-transform: uppercase font-medium bg-red-700 duration-200"
          }
        >
          <p>{formErrors.phone}</p>
        </div>
        {/* Message Error Notification */}
        <div
          className={
            formErrors.message
              ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[287px] right-[0px] text-transform: uppercase font-medium bg-red-700 duration-500"
              : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[287px] right-[-250px] text-transform: uppercase font-medium bg-red-700 duration-200"
          }
        >
          <p>{formErrors.message}</p>
        </div>
      </div>

      {/* Message Sent Response Success Notification */}
      <div
        className={
          successNotification
            ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[0px] text-transform: uppercase font-medium bg-green-700 duration-500"
            : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[-250px] text-transform: uppercase font-medium bg-green-700 duration-200"
        }
      >
        <p>Message sent!</p>
      </div>

      {/* Message Sent Response Error Notification */}
      <div
        className={
          errorNotification
            ? "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[0px] text-transform: uppercase font-medium bg-red-700 duration-500"
            : "text-white fixed w-[250px] h-[50px] flex flex-col justify-center text-center px-5 z-[95] top-[125px] right-[-250px] text-transform: uppercase font-medium bg-red-700 duration-200"
        }
      >
        <p>Message not sent! Please try again.</p>
      </div>
    </div>
  );
};

export default Contact;
