import React from "react";
import { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaGithub,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import LogoMP4 from "../assets/Logo.mp4";
import LogoMOV from "../assets/Logo.mov";
import LogoWEBM from "../assets/Logo.webm";
import Resume from "../assets/Andrea Rizzo Resume.pdf";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed z-[100] w-full h-[100px] flex justify-between items-center px-10 bg-[#0a192f] text-gray-400 shadow-xl shadow-pink-700/75">
      {/* Logo Video Loop */}
      <Link to="home" smooth={true} delay={500} duration={800}>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-[80px] cursor-pointer"
        >
          <source src={LogoMP4} type="video/mp4"></source>
          <source src={LogoWEBM} type="video/webm"></source>
          <source src={LogoMOV} type="video/mov"></source>
        </video>
      </Link>

      {/* Menu */}
      <ul className="hidden md:flex text-xl font-medium text-transform: uppercase">
        <li className=" hover:text-pink-700 duration-300">
          <Link to="home" smooth={true} delay={500} duration={800}>
            Home
          </Link>
        </li>
        <li className=" hover:text-pink-700 duration-300">
          <Link to="about" smooth={true} delay={500} duration={800}>
            About
          </Link>
        </li>
        <li className=" hover:text-pink-700 duration-300">
          <Link to="skills" smooth={true} delay={500} duration={800}>
            Skills
          </Link>
        </li>
        <li className=" hover:text-pink-700 duration-300">
          <Link to="projects" smooth={true} delay={500} duration={800}>
            Projects
          </Link>
        </li>
        <li className=" hover:text-pink-700 duration-300">
          <Link to="contact" smooth={true} delay={500} duration={800}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-[100] cursor-pointer">
        {!nav ? (
          <FaBars size={30} style={{ color: "#ccd6f6" }} />
        ) : (
          <FaTimes size={30} style={{ color: "rgb(190 24 93)" }} />
        )}
      </div>

      {/* Menu - Mobile */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute z-[90] md:hidden top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
        }
      >
        <li className="py-2 sm:py-3 text-2xl font-medium text-transform: uppercase hover:text-pink-700 duration-300">
          <Link
            onClick={handleClick}
            to="home"
            smooth={true}
            delay={500}
            duration={800}
          >
            Home
          </Link>
        </li>
        <li className="py-2 sm:py-3 text-2xl font-medium text-transform: uppercase  hover:text-pink-700 duration-300">
          <Link
            onClick={handleClick}
            to="about"
            smooth={true}
            delay={500}
            duration={800}
          >
            About
          </Link>
        </li>
        <li className="py-2 sm:py-3 text-2xl font-medium text-transform: uppercase hover:text-pink-700 duration-300">
          <Link
            onClick={handleClick}
            to="skills"
            smooth={true}
            delay={500}
            duration={800}
          >
            Skills
          </Link>
        </li>
        <li className="py-2 sm:py-3 text-2xl font-medium text-transform: uppercase  hover:text-pink-700 duration-300">
          <Link
            onClick={handleClick}
            to="projects"
            smooth={true}
            delay={500}
            duration={800}
          >
            Projects
          </Link>
        </li>
        <li className="py-2 sm:py-3 text-2xl font-medium text-transform: uppercase hover:text-pink-700 duration-300">
          <Link
            onClick={handleClick}
            to="contact"
            smooth={true}
            delay={500}
            duration={800}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Social Icons */}
      <div className="hidden lg:flex fixed flex-col top-[calc(50%_-_150px)] pt-[60px] left-0 text-transform: uppercase">
        <ul>
          {/* Instagram */}
          <li
            className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300"
            style={{
              background:
                "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
            }}
          >
            <a
              className="w-full text-white"
              href="https://www.instagram.com/andrearizzomt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <FaInstagram size={30} style={{ color: "white" }} />
          </li>

          {/* Facebook */}
          <li className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#3b5998] ">
            <a
              className="w-full text-white"
              href="https://www.facebook.com/arizzomt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <FaFacebookF size={30} style={{ color: "white" }} />
          </li>

          {/* Linkedin */}
          <li className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#0077b5] ">
            <a
              className="w-full text-white"
              href="https://www.linkedin.com/in/andrearizzomt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
            <FaLinkedinIn size={30} style={{ color: "white" }} />
          </li>

          {/* Resume / CV */}
          <li className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a
              className="w-full text-white"
              href={Resume}
              target="_blank"
              rel="noopener noreferrer"
            >
              My Resume
            </a>
            <BsFillPersonLinesFill size={30} style={{ color: "white" }} />
          </li>

          {/* Github */}
          <li className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="w-full text-white"
              href="https://github.com/andrearizzomt?tab=repositories"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
            <FaGithub size={30} style={{ color: "white" }} />
          </li>

          {/* Contact */}
          <li className="w-[175px] h-[50px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <Link
              to="contact"
              smooth={true}
              delay={500}
              duration={800}
              className="w-full text-white"
            >
              Contact
            </Link>

            <Link
              to="contact"
              smooth={true}
              offset={-50}
              delay={500}
              duration={800}
            >
              <HiOutlineMail size={25} style={{ color: "white" }} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
