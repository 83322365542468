import React from "react";
import { Link } from "react-scroll";
import Logo from "../assets/Logo.png";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaGithub,
} from "react-icons/fa";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Resume from "../assets/Andrea Rizzo Resume.pdf";

const Footer = () => {
  return (
    <div>
      <div className="px-10 lg:px-0 mt-3 md:mt-5">
        <hr className="max-w-[975px] mx-auto border-1 border-gray-700 " />
      </div>
      <div className="z-[100] w-full h-auto flex justify-center items-center py-12 px-3 sm:px-10 text-gray-500">
        {/* Container */}
        <div className="max-w-[975px] w-[100%] flex justify-between items-center flex-col md:flex-row gap-5 ">
          {/* Footer Socials Icons and Menu */}
          <div className="flex flex-col items-center gap-5">
            {/* Socials Icons */}
            <ul className="w-[100%] flex flex-wrap justify-center sm:justify-between items-center px-2 gap-2 sm:gap-5 text-sm text-transform: uppercase">
              {/* Instagram */}
              <a
                className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-gray-700 group instagram"
                href="https://www.instagram.com/andrearizzomt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FaInstagram
                    size={26}
                    className=" text-gray-500 group-hover:text-white"
                  />
                </li>
              </a>
              {/* Facebook */}
              <a
                className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-gray-700 group hover:bg-[#3b5998]"
                href="https://www.facebook.com/arizzomt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FaFacebookF
                    size={24}
                    className=" text-gray-500 group-hover:text-white"
                  />
                </li>
              </a>
              {/* Linkedin */}
              <a
                className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-gray-700 group hover:bg-[#0077b5]"
                href="https://www.linkedin.com/in/andrearizzomt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FaLinkedinIn
                    size={26}
                    className=" text-gray-500 group-hover:text-white"
                  />
                </li>
              </a>
              {/* Resume / CV */}
              <a
                className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-gray-700 group hover:bg-[#565f69]"
                href={Resume}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <BsFillPersonLinesFill
                    size={23}
                    className=" text-gray-500 group-hover:text-white"
                  />
                </li>
              </a>
              {/* Github */}
              <a
                className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-gray-700 group hover:bg-[#333333]"
                href="https://github.com/andrearizzomt?tab=repositories"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FaGithub
                    size={26}
                    className=" text-gray-500 group-hover:text-white"
                  />
                </li>
              </a>
            </ul>
            {/* Menu */}
            <ul className="flex flex-col sm:flex-row text-center text-sm text-transform: uppercase">
              <li className="px-2 hover:text-pink-700 duration-300">
                <Link to="home" smooth={true} delay={500} duration={800}>
                  Home
                </Link>
              </li>
              <li className="hidden sm:block px-1 text-gray-700">|</li>
              <li className="px-2 hover:text-pink-700 duration-300">
                <Link to="about" smooth={true} delay={500} duration={800}>
                  About
                </Link>
              </li>
              <li className="hidden sm:block px-1 text-gray-700">|</li>
              <li className="px-2 hover:text-pink-700 duration-300">
                <Link to="skills" smooth={true} delay={500} duration={800}>
                  Skills
                </Link>
              </li>
              <li className="hidden sm:block px-1 text-gray-700">|</li>
              <li className="px-2 hover:text-pink-700 duration-300">
                <Link to="projects" smooth={true} delay={500} duration={800}>
                  Projects
                </Link>
              </li>
              <li className="hidden sm:block px-1 text-gray-700">|</li>
              <li className="px-2 hover:text-pink-700 duration-300">
                <Link to="contact" smooth={true} delay={500} duration={800}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          {/* Copyright */}
          <div className="flex flex-wrap text-center justify-center items-center text-sm pr-2">
            <p>
              Copyright <span className="font-bold">©</span> 2022
              <Link to="home" smooth={true} delay={500} duration={800}>
                <span className="text-pink-700 font-medium cursor-pointer ">
                  {" "}
                  Andrea Rizzo
                </span>
              </Link>
            </p>
            <Link to="home" smooth={true} delay={500} duration={800}>
              <img
                className="ml-1 w-6 cursor-pointer"
                src={Logo}
                alt="andrea rizzo logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
